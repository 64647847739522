import React from "react";
import { Box, Card, CardContent, CardMedia, Grid, Typography } from '@mui/material';
import CategoryIcon from "../shared/components/CategoryIcon";
import { mobileTheme } from "../themeMobile";
import { UNLIMITED_COUPON } from "../shared/constants/AppConsts";

const BrochureCardCouponAd = (props) => {

    const couponDetailsStyles = {
        backgroundColor: '#fdeae4',
        textAlign: 'center',
        borderRight: '0.2em solid #fff',
        height: 75
    }

    const toDateMonthFormat = (dateStr) => {    // dateStr => "DD/MM/YYYY"
        let vals = dateStr.split('/').map((s) => Number(s));
        let dateUTC = new Date(Date.UTC(null, vals[1] - 1, vals[0]));
        return dateUTC.toLocaleDateString('en-GB', { month: 'short', day: 'numeric' })
    }

    const toDateMonthYearFormat = (dateStr) => {    // dateStr => "DD/MM/YYYY"
        const parts = dateStr.split("/");
        return `${parts[0]}/${parts[1]}/${parts[2].slice(-2)}`;
    }

    const BlueBox = (props) => {
        return (
            <Grid container item direction='column' sx={couponDetailsStyles} xs={props.size ? props.size : 3} justifyContent='space-evenly' overflow='-moz-hidden-unscrollable'>
                {props.children ?
                    props.children.map((child, index) => {
                        return (
                            <Grid item key={index}>
                                {child}
                            </Grid>
                        )
                    })
                    : <></>}
            </Grid>
        )
    }

    const getCompanyBox = () => {
        return (
            <Box
                sx={{
                    display: 'flex', justifyContent: 'center', alignItems: 'center', height: '280px', backgroundColor: mobileTheme.palette.grey[200]
                }} >
                <Box sx={{
                    display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50%', width: '80%', backgroundColor: mobileTheme.palette.grey[400],
                    borderRadius: '240px'
                }}>
                    <Typography sx={{ fontSize: '1.3rem', fontWeight: 700, color: mobileTheme.palette.background.paper }}>
                        {props.companyName}
                    </Typography>
                </Box>

            </Box>
        )
    }

    let { normalPrice, discountedPrice, numberOfCoupons } = props;

    let isUnlimitedCoupon = numberOfCoupons === UNLIMITED_COUPON;

    let savedAmount = 'N/A';
    let discountPrecentage = 'N/A';
    if (!isUnlimitedCoupon && normalPrice && discountedPrice) {
        savedAmount = (normalPrice - discountedPrice).toFixed(2);
        discountPrecentage = ((savedAmount) * 100 / normalPrice).toFixed(2) + '%';
        savedAmount = '$' + savedAmount;
    }
    normalPrice = !isUnlimitedCoupon && normalPrice ? '$' + normalPrice : 'N/A';
    discountedPrice = !isUnlimitedCoupon && discountedPrice ? '$' + discountedPrice : 'N/A';

    return (
        <Card sx={{ backgroundColor: 'white', mx: 1, my: 3, position: 'relative', border: 'none', overflow: 'auto' }} variant='outlined' onClick={props.onClick}>

            {/* Image */}
            <Box sx={{ backgroundColor: '#f2f2f2' }}>
                {props.img && <CardMedia
                    component="img"
                    height="280"
                    image={props.img}
                />}
            </Box>

            {!props.img && getCompanyBox()}

            {/* Details */}
            <CardContent>
                <Box height={'100%'} sx={{ borderLeft: 4, borderColor: '#FF9830', px: 3 }}>
                    <Grid container justifyContent={'space-between'} marginBottom={1}>
                        <Grid item xs={10} sx={{ textTransform: 'uppercase' }}>
                            <Typography gutterBottom variant="h6" component="div">
                                {props.title ? props.title : props.companyName}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <CategoryIcon categoryCode={props.categoryCode} />
                        </Grid>
                    </Grid>
                    <Typography variant="body2" color="text.secondary" marginBottom={2.5}>
                        {props.highlights}
                    </Typography>

                    <Grid container sx={{ width: '100%', mb: 1 }}>
                        
                        <Grid item xs={12} sx={{ margin: 'auto' }} textAlign={'center'}>
                            { !isUnlimitedCoupon && <Typography sx={{ fontWeight: 'thin', fontSize: '1.1rem' }}>Normally {normalPrice}</Typography>}
                            { isUnlimitedCoupon && <Typography sx={{ fontWeight: 'thin', fontSize: '1.1rem' }}>&nbsp;</Typography>}
                        </Grid>
                        {/* <Grid item xs={6}>
                            <img src="/images/get-coupon-btn2.svg" alt="Get Coupon"/>
                        </Grid> */}
                    </Grid>

                    { !isUnlimitedCoupon && <Typography variant="body2" sx={{ fontWeight: 'bold', mb: 1, color: '#FF9830', textAlign: 'center' }}>Save {savedAmount}</Typography>}
                    { isUnlimitedCoupon && <Typography variant="body2" sx={{ fontWeight: 'bold', mb: 1, color: '#FF9830', textAlign: 'center' }}>&nbsp;</Typography>}

                    {!isUnlimitedCoupon &&
                        <Grid container sx={{ fontSize: '0.9rem' }}>
                            <BlueBox>
                                <Box sx={{ fontWeight: 'bold' }}>{discountedPrice}</Box>
                                <Box>PRICE</Box>
                            </BlueBox>
                            <BlueBox>
                                <Box sx={{ fontWeight: 'bold' }}>{discountPrecentage}</Box>
                                <Box>DISC.</Box>
                            </BlueBox>
                            <BlueBox>
                                <Box sx={{ fontWeight: 'bold' }}>{isUnlimitedCoupon ? 'N/A' : (props.availableCoupons + '/' + props.numberOfCoupons)}</Box>
                                <Box>LEFT</Box></BlueBox>
                            <BlueBox>
                                <Box sx={{ fontWeight: 'bold' }}>{toDateMonthYearFormat(props.validFrom)}</Box>
                                <Box sx={{ fontWeight: 'bold' }}>{toDateMonthYearFormat(props.validTo)}</Box>
                                <Box>VALID</Box>
                            </BlueBox>
                        </Grid>
                    }

                    {isUnlimitedCoupon &&
                        <Grid>
                            <BlueBox size="12">
                                <Box sx={{ fontWeight: 'bold' }}>{toDateMonthYearFormat(props.validFrom)}</Box>
                                <Box sx={{ fontWeight: 'bold' }}>{toDateMonthYearFormat(props.validTo)}</Box>
                                <Box>VALID</Box>
                            </BlueBox>
                        </Grid>
                    }

                </Box>
            </CardContent>
        </Card>
    )
}

export default BrochureCardCouponAd